const countriesModule = {
  firestorePath: 'Countries',
  firestoreRefType: 'collection',
  moduleName: 'countriesModule',
  statePropName: 'data',
  namespaced: true,
  sync: {
    preventInitialDocInsertion: true,
  },

  state: {
    countries: {},
  },
  getters: {
    getCountries: (state) => {
      return state.data;
    },
  },
  mutations: {
    UPDATE_COUNTRIES(state, doc) {
      localStorage.setItem('Countries', JSON.stringify(doc));
      Object.assign(state.countries, doc);
    },
  },
};

export default countriesModule;
