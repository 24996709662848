/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbarSearchAndPinList';
import themeConfig from '@/../themeConfig.js';
import colors from '@/../themeConfig.js';

const state = {
  activeApp: null,
  purchaseCredits: {
    active: false,
    creditType: '',
    title: '',
    text: '',
  },
  generalPopup: {
    active: false,
    title: '',
    text: '',
    buttonText: '',
    click: () => {
      return;
    },
  },
  promotionModal: {
    active: false,
    title: '',
    text: '',
    buttonText: '',
    click: () => {
      return;
    },
  },
  purchasingCredits: false,
  selectedCreditType: '',
  isSidebarActive: true,
  sidebarOpen: false,
  breakpoint: null,
  sidebarWidth: 'default',
  reduceButton: themeConfig.sidebarCollapsed,
  bodyOverlay: false,
  sidebarItemsMin: false,
  theme: themeConfig.theme || 'light',
  navbarSearchAndPinList: navbarSearchAndPinList,
  AppActiveUser: {
    id: 0,
    name: 'John Doe',
    about: 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    img: 'avatar-s-11.png',
    status: 'online',
  },
  tokens: [],
  imgArray: [
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F1.jpeg?alt=media&token=65fa5b5b-cb55-4a0c-b3f5-a42376033177',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F9.jpeg?alt=media&token=65fa5b5b-cb55-4a0c-b3f5-a42376033177',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F3.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F4.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F5.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F6.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F7.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F8.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F9.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F10.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F11.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F12.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F13.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F14.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F15.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
    'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/banners%2F16.jpg?alt=media&token=3e22c8e9-2aaf-47ab-8d32-5718d89034ae',
  ],

  messagePayload: {},

  themePrimaryColor: colors.primary,

  starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  userRole: null,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar component
  windowWidth: null,
};

export default state;
