/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Router from 'vue-router';

// import firebase from 'firebase/app';
// import 'firebase/auth';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import store from '../src/store/store';

// import ApplicationUserProfile from './views/components/application-components/ApplicationUserProfile.vue';
// import ApplicationVehicles from './views/components/application-components/ApplicationVehicles.vue';
// import ApplicationJobHistory from './views/components/application-components/ApplicationJobHistory.vue';
// import ApplicationGuideInfo from './views/components/application-components/ApplicationGuideInfo.vue';
// import ApplicationLanguagesAndSkills from './views/components/application-components/ApplicationLanguagesAndSkills.vue';
// import ApplicationQualifications from './views/components/application-components/ApplicationQualifications.vue';
// import ApplicationReferences from './views/components/application-components/ApplicationReferences.vue';

import ProfileUserProfile from './views/components/guide-profile-components/ProfileUserProfile.vue';
import ProfileVehicles from './views/components/guide-profile-components/ProfileVehicles.vue';
import ProfileJobHistory from './views/components/guide-profile-components/ProfileJobHistory.vue';
import ProfileGuideInfo from './views/components/guide-profile-components/ProfileGuideInfo.vue';
import ProfileLanguagesAndSkills from './views/components/guide-profile-components/ProfileLanguagesAndSkills.vue';
import ProfileQualifications from './views/components/guide-profile-components/ProfileQualifications.vue';
import ProfileReferences from './views/components/guide-profile-components/ProfileReferences.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      /*       console.log(to.hash);
       */ return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ selector: to.hash });
        }, 1000);
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/pages/profile',
          name: 'Profile',
          component: () => import('./views/pages/Profile.vue'),
        },
        {
          path: '/apps/chat',
          name: 'app-chat',
          component: () => import('./views/apps/chat/Chat.vue'),
          meta: {
            rule: 'editor',
          },
          props: true,
        },
        {
          path: '/pages/chat',
          name: 'chat',
          component: () => import('./views/apps/chat/Chat.vue'),
          meta: {
            rule: 'editor',
          },
          props: true,
        },
        {
          path: '/pages/resume',

          component: () => import('./views/pages/ResumeDatabase.vue'),
          meta: {
            rule: 'editor',
          },
          props: true,
          children: [
            {
              path: '/',
              component: ProfileUserProfile,
              props: true,
              children: [
                {
                  path: '/pages/resume/guide-info',
                  name: 'resume-guide-information',
                  component: ProfileGuideInfo,
                  props: true,
                },
                {
                  path: '/pages/resume/language-and-skills',
                  name: 'resume-language-skills-interests',
                  component: ProfileLanguagesAndSkills,
                  props: true,
                },
                {
                  path: '/pages/resume/vehicles',
                  name: 'resume-vehicles',
                  component: ProfileVehicles,
                  props: true,
                },
                {
                  path: '/pages/resume/job-history',
                  name: 'resume-job-history',
                  component: ProfileJobHistory,
                  props: true,
                },
                {
                  path: '/pages/resume/qualifications',
                  name: 'resume-qualifications-certificates',
                  component: ProfileQualifications,
                  props: true,
                },
                {
                  path: '/pages/resume/references',
                  name: 'resume-references',
                  component: ProfileReferences,
                  props: true,
                },
              ],
            },
          ],
        },
        {
          path: '/',
          name: 'Applications',
          component: () => import('./views/pages/Applications.vue'),
          meta: {
            rule: 'editor',
          },
          props: true,
          children: [
            {
              path: '/pages/applications',
              name: 'ApplicationIn',
              component: ProfileUserProfile,
              props: true,
              children: [
                {
                  path: 'guide-info',
                  name: 'Guide Information',
                  component: ProfileGuideInfo,
                  props: true,
                },
                {
                  path: 'language-and-skills',
                  name: 'Language, Skills & Interests',
                  component: ProfileLanguagesAndSkills,
                  props: true,
                },
                {
                  path: 'vehicles',
                  name: 'Vehicles',
                  component: ProfileVehicles,
                  props: true,
                },

                {
                  path: 'job-history',
                  name: 'Job History',
                  component: ProfileJobHistory,
                  props: true,
                },
                {
                  path: 'qualifications',
                  name: 'Qualifications & Certificates',
                  component: ProfileQualifications,
                  props: true,
                },
                {
                  path: 'references',
                  name: 'References',
                  component: ProfileReferences,
                  props: true,
                },
              ],
            },
          ],
        },
        {
          path: '/pages/jobs',
          name: 'Jobs',
          component: () => import('./views/pages/Jobs.vue'),
          props: true,
        },
        {
          path: '/pages/settings',
          name: 'Settings',
          component: () => import('./views/pages/Settings.vue'),
          props: true,
        },
        {
          path: '/pages/archived-jobs',
          name: 'Archived Jobs',
          component: () => import('./views/pages/ArchivedJobs.vue'),
          props: true,
        },
        {
          path: '/pages/notifications',
          name: 'Notifications',
          component: () => import('./views/pages/Notifications.vue'),
        },
        {
          path: '/pages/dashboard',
          name: 'Dashboard',
          component: () => import('./views/pages/Dashboard.vue'),
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pages/login',
          name: 'pageLogin',
          component: () => import('@/views/pages/Login.vue'),
        },
        {
          path: '/pages/register',
          name: 'pageRegister',
          component: () => import('@/views/pages/Register.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/pages/verify-email/:emailSent',
          // path: '/pages/verify-email/',
          name: 'verifyEmail',
          component: () => import('@/views/pages/VerifyEmail.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/pages/user-details',
          name: 'createUser',
          component: () => import('@/views/pages/CreateUser.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/pages/forgot-password',
          name: 'forgotPassword',
          component: () => import('@/views/pages/ForgotPassword.vue'),
        },
        {
          path: '/pages/user-management',
          name: 'userManagement',
          component: () => import('@/views/pages/UserManagement.vue'),
          props: true,
        },

        {
          path: '/pages/error-404',
          name: 'pageError404',
          component: () => import('@/views/pages/Error404.vue'),
        },
        {
          path: '/pages/reference-verification',
          name: 'referenceVerification',
          component: () => import('@/views/pages/ReferenceVerification.vue'),
        },
      ],
    },

    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404',
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const unsubscribe = onAuthStateChanged(auth, (firebaseCurrentUser) => {
    if (firebaseCurrentUser) {
      if (firebaseCurrentUser.isAnonymous) {
        auth.signOut();
      }
      if ((to.path === '/' || to.path === '/pages/login') && to.path !== '/pages/dashboard') {
        return router.push({ path: '/pages/dashboard' });
      }
      if (firebaseCurrentUser && to.path === '/pages/user-management') {
        return next();
      }
      if (firebaseCurrentUser && to.name === 'verifyEmail') {
        return next();
      }
      if (!firebaseCurrentUser.emailVerified && to.name !== 'verifyEmail') {
        return router.push({ name: 'verifyEmail', params: { emailSent: false } });
      }

      if (firebaseCurrentUser.emailVerified) {
        store.dispatch('employerModule/openDBChannel').then(() => {
          if (
            ((to.path === '/pages/settings' && from.path === '/') ||
              (to.path === '/pages/profile' && from.path === '/') ||
              (to.path === '/pages/applications' && from.path === '/')) &&
            to.path !== '/pages/dashboard'
          ) {
            return router.push({ path: '/pages/dashboard' });
          }

          const employer = store.state.employerModule.data;

          if (!employer.name && to.path !== '/pages/login' && to.path !== '/pages/user-details') {
            return router.push({ path: '/pages/user-details' });
          }
          if (employer.name && to.path === '/pages/user-details' && to.path !== '/pages/dashboard') {
            return router.push({ path: '/pages/dashboard' });
          }
          return next();
        });
      }
    }

    if (
      to.path === '/pages/reference-verification' ||
      to.path === '/pages/login' ||
      to.path === '/pages/reference-verification' ||
      to.path === '/pages/forgot-password' ||
      to.path === '/pages/error-404' ||
      to.path === '/pages/error-500' ||
      to.path === '/pages/register' ||
      to.path === '/pages/comingsoon' ||
      to.path === '/pages/user-management'
    ) {
      return next();
    }
    if (!firebaseCurrentUser && to.path !== '/pages/login') {
      router.push({ path: '/pages/login' });
    }
  });
  unsubscribe();
});

export default router;
