const notificationModule = {
  firestorePath: 'notifications',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'notificationModule',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    preventInitialDocInsertion: true,
  },
  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getNotification: (state) => {
      return state.data;
    },
  },
  mutations: {},
  actions: {},
};

export default notificationModule;
