<template>
  <div>
    <div class="skills-interests">
      <div class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Skills and Interests</p>
          <ul>
            <li v-for="(skills, index) in input.specArea" :key="index"
              ><p class="item-name font-semibold my-1"> {{ skills }}</p></li
            >
          </ul>
        </div>
      </div>
    </div>
    <p v-if="input.languagesNew" class="text-xs text-dark-grey mb-2 mt-4">Languages</p>
    <guide-audio-player
      v-for="(language, index) in input.languagesNew"
      :key="index"
      :language="language"
      :index="index"
    ></guide-audio-player>
  </div>
</template>

<script>
import GuideAudioPlayer from './GuideAudioPlayer.vue';

export default {
  name: 'ApplicationLanguagesAndSkills',
  components: {
    GuideAudioPlayer,
  },
  props: {
    input: {
      type: Object,
      required: true,
    },
  },
};
</script>
