const paymentModule = {
  firestorePath: 'payments',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'paymentModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  sync: {
    preventInitialDocInsertion: true,
    insertHookBeforeSync: function (updateFirestore, doc, store) {
      store.commit('paymentModule/ADD_ID', doc.id);
      updateFirestore(doc);
    },

    default: {
      employer_id: '',
      employer_name: '',
      m_payment_id: '',
      pf_payment_id: '',
      amount_gross: 0,
      amount_fee: 0,
      amount_net: 0,
      freelance_credits: 0,
      permanent_credits: 0,
      item_name: '',
      item_description: '',
      payment_status: '',
      created_at: Date.now(),
      updated_at: Date.now(),
    },
  },
  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {
    paymentId: '',
  },
  getters: {
    getId: (state) => {
      return state.paymentId;
    },
  },
  mutations: {
    ADD_ID(state, id) {
      state.paymentId = id;
    },
  },
  actions: {},
};

export default paymentModule;
