<template>
  <div class="grid grid-cols-1 py-2">
    <div class="col-span-1">
      <vx-card v-for="(ref, index) in input" :key="index" class="mb-5 border-solid border-2 border-grey-light">
        <div v-if="!ref.reported" class="pt-0 sm:px-2 flex flex-col">
          <div class="flex justify-between">
            <div class="flex flex-col">
              <div>
                <h6 class="item-name font-semibold mb-1">
                  {{ ref.name || 'No name provided' }}
                </h6>
                <p class="text-sm mb-4">
                  From
                  <span> {{ ref.company }}</span>
                </p>
              </div>
            </div>
            <div class="mb-3 sm:mb-0">
              <vs-chip
                v-if="!ref.reported"
                :class="ref.verified ? 'text-black' : 'text-white'"
                :color="ref.verified ? '#d9e7b4' : 'grey'"
                >{{ verifiedText(ref) }}</vs-chip
              >
              <!-- <vs-chip v-if="ref.reported" class="text-white" color="#ea5455">Reported</vs-chip> -->
            </div>
          </div>
          <div class="flex flex-col">
            <div v-if="ref.contactPhone" class="flex">
              <span class="material-icons mr-1 text-lg">call</span>
              <p class="text-sm font-semibold">
                {{ ref.contactPhone }}
              </p>
            </div>
            <div v-if="ref.contactEmail" class="flex mt-1">
              <span class="material-icons mr-1 text-lg">mail</span>
              <p class="text-sm font-semibold">
                {{ ref.contactEmail }}
              </p>
            </div>
            <p class="text-sm text-dark-grey mt-4"> Job title </p>
            <p class="text-sm">
              {{ ref.title }}
            </p>
            <div v-if="ref.verified && ref.referenceComment && ref.referenceComment !== ''">
              <p class="text-sm text-dark-grey mt-4"> Reference comment </p>
              <p class="text-sm">
                {{ ref.referenceComment }}
              </p>
            </div>
            <div v-if="ref.reported && ref.reportComment && ref.reportComment !== ''">
              <p class="text-sm text-dark-grey mt-4"> Why was this reference reported? </p>
              <p class="text-sm">
                {{ ref.reportComment }}
              </p>
            </div>
            <!-- <p class="text-sm text-grey">
              {{ getDate(ref.fromDate) }} - <span v-if="!ref.current">{{ getDate(ref.toDate) }}</span>
              <span v-else>Present ({{ dateDifference(ref.fromDate) }})</span>
            </p> -->
            <!-- <p class="font-medium min-w-full">
              {{ ref.description }}
            </p> -->
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ApplicationReferences',
  props: {
    input: {
      type: Array,
      required: true,
    },
  },
  methods: {
    verifiedText(ref) {
      if (ref.verified) {
        return 'Verified';
      }
      if (!ref.verified) {
        return 'Unverified';
      }
    },
    dateDifference(fromDate) {
      const currentDate = moment(new Date());
      const date = new Date(fromDate._seconds * 1000);
      const dateDiff = currentDate.diff(date, 'years', true).toFixed(2);
      const years = dateDiff.split('.')[0];
      const months = Math.round((12 * +dateDiff.split('.')[1]) / 100);
      const yearText = +years > 1 ? 'yrs' : 'yr';
      const monthText = months > 1 ? 'mos' : 'mo';
      if (years === '0') {
        return `${months} ${monthText}`;
      } else return `${years} ${yearText} ${months} ${monthText}`;
    },
    getDate(timeStamp) {
      let date;
      if (timeStamp._seconds) {
        date = new Date(timeStamp._seconds * 1000);
      }
      if (timeStamp.seconds) {
        date = new Date(timeStamp.seconds * 1000);
      }
      return moment(date.toISOString()).format('DD/MM/YYYY');
    },
  },
};
</script>
