const applicationsModule = {
  firestorePath: 'applications',
  firestoreRefType: 'collection',
  moduleName: 'applicationsModule',
  statePropName: 'data',
  namespaced: true,
  serverChange: {
    addedHook: function (updateStore, doc, id, store) {
      store.commit('applicationsModule/addId', {
        userId: doc.userId,
        jobId: doc.jobId,
      });
      updateStore(doc);
    },
    modifiedHook: function (updateStore, doc) {
      updateStore(doc);
    },
    removedHook: function (updateStore, doc, id, store) {
      store.commit('applicationsModule/addId', {
        [doc.jobId]: doc.userId,
      });
      updateStore(doc);
    },
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      createdAt: '%convertTimestamp%', // default
      updatedAt: '%convertTimestamp%', // default
    },
  },
  sync: {
    //TODO: update firestore
    preventInitialDocInsertion: false,
    insertHookBeforeSync: function (updateFirestore, doc, store) {
      // eslint-disable-next-line no-param-reassign
      doc.applicationsId = doc.id;
      store.commit('applicationsModule/addId', {
        [doc.jobId]: doc.userId,
      });
      updateFirestore(doc);
    },
    default: {
      employerId: '',
      userId: '',
      applicationsId: '',
      compId: '',
      jobId: '',
      status: '',
      offerAccepted: false,
      employerReject: false,
      createdAt: '',
      updatedAt: '',
      userData: {
        userName: '',
        userImage: '',
        userVideo: '',
        location: '',
        city: '',
      },
      jobData: {
        jobTitle: '',
        jobImage: '',
        compName: '',
        location: '',
        city: '',
      },
    },
  },

  state: {
    idJobUser: {},
    count: 0,
  },
  getters: {
    DataOfUser: (state) => (id) => {
      return state.data[id];
    },
    getCount: (state) => {
      return state.count;
    },
  },
  mutations: {
    addId(state, obj) {
      var arr = [];

      arr = state.idJobUser[obj.jobId] || [];
      arr.push(obj.userId);
      const newObject = {
        [obj.jobId]: arr,
      };
      const newObj = { ...state.idJobUser, ...newObject };
      state.idJobUser = newObj;
      state.count++;
    },
    removeId(state, key) {
      delete state.idJobUser[key];
    },
  },
  actions: {
    updateApplication({ dispatch }, payload) {
      if (payload) {
        dispatch('patch', { ...payload });
        /*         .catch(console.error);
         */
      }
    },
    createApplication({ dispatch }, payload) {
      const userData = {
        userName:
          payload.user.firstName && payload.user.firstName !== '' && payload.user.lastName && payload.user.lastName !== ''
            ? payload.user.firstName + ' ' + payload.user.lastName
            : payload.user.fullName,
        userImage: payload.user.profileImage,
        userVideo: payload.user.profileVideo,
        location: payload.user.location,
        city: payload.user.provinces,
      };
      const jobData = {
        jobTitle: payload.job.jobTitle,
        jobImage: payload.job.jobImage,
        compName: payload.job.compName,
        location: payload.job.location,
        city: payload.job.cities,
      };
      //TODO: Add comp ID and app ID
      const application = {
        employerId: payload.empId,
        userId: payload.user.userId,
        applicationsId: '',
        compId: '',
        jobId: payload.job.id,
        status: 1,
        offerAccepted: false,
        invitationAccepted: false,
        employerReject: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        userData: userData,
        jobData: jobData,
      };
      dispatch('set', application);
    },
  },
};

export default applicationsModule;
