let timesCalled = 0;
let timesCalledCountDown = 15;
let requestCountDown = 60;
let timerRunning = false;
let requestTimerRunning = false;

function timesCalledTimer() {
  if (!timerRunning && timesCalledCountDown === 0) {
    timesCalledCountDown = 15;
    timesCalled = 0;
  }
  if (timesCalledCountDown > 0) {
    timerRunning = true;
    setTimeout(() => {
      timesCalledCountDown -= 1;
      timesCalledTimer();
    }, 1000);
  } else {
    timerRunning = false;
  }
}
function requestTimer() {
  if (!requestTimerRunning && requestCountDown === 0) {
    requestCountDown = 60;
  }
  if (requestCountDown > 0) {
    requestTimerRunning = true;
    setTimeout(() => {
      requestCountDown -= 1;
      requestTimer();
    }, 1000);
  } else {
    requestTimerRunning = false;
  }
}

export function tooManyRequests() {
  if (requestTimerRunning) throw new Error('too-many-requests');
  if (!timerRunning) {
    timesCalledTimer();
  }
  timesCalled += 1;
  if (timesCalled >= 3 && timesCalledCountDown > 0) {
    requestTimer();
    throw new Error('too-many-requests');
  }
  return true;
}
