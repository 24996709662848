import router from '@/router';
const employerModule = {
  firestorePath: 'employers/{userId}',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'employerModule',
  statePropName: 'data',
  namespaced: true, // automatically added
  serverChange: {
    modifiedHook: function (updateStore, doc, id, store) {
      store.commit('employerModule/UPDATE_AUTHENTICATED_USER', doc);
      updateStore(doc);
    },
    convertTimestamps: {
      created_at: '%convertTimestamp%',
      updated_at: '%convertTimestamp%',
    },
  },

  sync: {
    // patchHookBeforeSync: function (updateStore, doc, store) {
    //   console.log(store.state.employerModule.data.created_at);
    //   return updateStore(doc);
    // },

    defaultValues: {
      employerId: '',
      active: true,
      emailVerified: false,
      imageUrl: '',
      addToSGSenderList: true,
      showPromotion: false,
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmed_password: '',
      telephone: '',
      mobile: '',
      position: '',
      companyName: '',
      companyImageUrl: '',
      companyIndustry: '',
      companyLocation: '',
      companyCountry: '',
      companyAddress: '',
      companyWebsite: '',
      companyRegNo: '',
      companyVatNo: '',
      currentPlan: 0,
      jobPosts: 0,
      permanentJobPosts: 0,
      freelanceJobPosts: 0,
      billContact: '',
      billAddress: '',
      billCity: '',
      billProvince: '',
      billZipCode: '',
      billContactNo: '',
      billEmail: '',
      notifications: '',
      tokens: [],
      dashboardTours: {
        profileTourSeen: false,
        jobsTourSeen: false,
        guideBoardTourSeen: false,
        applicationsTourSeen: false,
        applicationProfileTourSeen: false,
        guideProfileTourSeen: false,
        chatTourSeen: false,
        archivedJobsTourSeen: false,
        settingsTourSeen: false,
        dashboardTourSeen: false,
      },
      emailPreferences: {
        newMessages: true,
        newApplicants: true,
        acceptedInvitations: true,
        jobExpiring: true,
        ragNewsletter: true,
        productUpdates: true,
      },
    },
  },

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getUser: (state) => {
      return state.data;
    },
    billingDetailsCheck: (state) => {
      return Object.keys(state.data).every((key) =>
        // /^(bill.+|.+Reg.+)$/;
        /(?=.*bill)(?!.*Fax)(.+)/gm.test(key) && (!state.data[key] || state.data[key] === '') ? false : true,
      );
      // return (
      //   state.data.billAddress !== '' &&
      //   state.data.billContact !== '' &&
      //   state.data.billContactNo !== '' &&
      //   state.data.billCity !== '' &&
      //   state.data.billZipCode !== '' &&
      //   state.data.billEmail !== '' &&
      //   state.data.billProvince !== ''
      //   // &&
      //   // state.data.companyRegNo !== '' &&
      //   // state.data.companyVatNo !== ''
      // );
    },
  },
  mutations: {
    UPDATE_AUTHENTICATED_USER(state, user) {
      localStorage.setItem('userInfo', JSON.stringify(user));
      localStorage.setItem('userRole', 'admin');
    },
    UPDATE_USER(state, payload) {
      state.data.employerId = payload.cred.user.uid;
      state.data.email = payload.userDetails.email;
      state.data.name = payload.userDetails.name;
      state.data.surname = payload.userDetails.surname;
      state.data.telephone = payload.userDetails.telephone;
      state.data.imageUrl = 'https://www.bsn.eu/wp-content/uploads/2016/12/user-icon-image-placeholder-300-grey.jpg';
      state.data.active = true;
      state.data.emailVerified = payload.cred.user.emailVerified;
      state.data.addToSGSenderList = payload.userDetails.addToSGSenderList;
      state.data.mobile = '';
      state.data.position = '';
      state.data.companyName = '';
      state.data.companyImageUrl = '';
      state.data.companyIndustry = '';
      state.data.companyLocation = '';
      state.data.companyCountry = '';
      state.data.companyAddress = '';
      state.data.companyWebsite = '';
      state.data.companyRegNo = '';
      state.data.companyVatNo = '';
      state.data.currentPlan = 0;
      state.data.jobPosts = 0;
      // state.data.permanentJobPosts = 1;
      // state.data.freelanceJobPosts = 1;
      state.data.billContact = '';
      state.data.billAddress = '';
      state.data.billCity = '';
      state.data.billProvince = '';
      state.data.billZipCode = '';
      state.data.billContactNo = '';
      state.data.billFaxNo = '';
      state.data.billEmail = '';
      state.data.notifications = '';
      state.data.tokens = [];
    },
    ADD_TOKEN(state, token) {
      console.log(state.data);
      state.data.tokens.push(token);
    },
    POSTED_JOB(state) {
      if (state.data.jobPosts > 0) state.data.jobPosts--;
    },
    POSTED_PERMANENT_JOB(state) {
      state.data.permanentJobPosts--;
    },
    POSTED_FREELANCE_JOB(state) {
      state.data.freelanceJobPosts--;
    },
  },
  actions: {
    handleUser(dispatch, payload) {
      const userData = dispatch.getters.getUser;

      if (userData == null || userData == undefined) {
        dispatch('patch', {
          name: payload.displayName,
          surname: payload.displayName,
          email: payload.email,
          imageUrl: payload.photoURL,
        });
      } else {
        if (router.currentRoute.fullPath != '/') router.push('/');
        // if (router.currentRoute.fullPath != '/') router.push(router.currentRoute.query.to || '/');
      }
    },
    handleToken({ commit, state, dispatch }, payload) {
      commit('ADD_TOKEN', payload);
      dispatch('patch', state.data);
    },
    postedJob({ commit, dispatch, state }) {
      commit('POSTED_JOB');
      dispatch('patch', state.data);
    },
    postedFreelanceJob({ commit, dispatch, state }, editing) {
      if (!editing) {
        commit('POSTED_FREELANCE_JOB');
      }
      dispatch('patch', state.data);
    },
    postedPermanentJob({ commit, dispatch, state }, editing) {
      if (!editing) {
        commit('POSTED_PERMANENT_JOB');
      }
      dispatch('patch', state.data);
    },
    createUser({ commit }, payload) {
      commit('UPDATE_USER', payload);
    },
  },
};

export default employerModule;
