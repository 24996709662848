<!-- eslint-disable vue/no-mutating-props -->
<!-- TODO FIX THIS -->
<template>
  <div id="profile-page" class="h-full flex overflow-hidden">
    <div class="flex flex-col min-w-0 flex-1 overflow-hidden">
      <div class="flex-1 relative z-0 flex overflow-hidden">
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
          <article>
            <div class="relative">
              <div class="absolute top-3 sm:top-5 left-5" @click="closeProfile()">
                <vs-icon size="medium" color="#22292f" icon="arrow_back" class="cursor-pointer"></vs-icon>
              </div>
              <div class="absolute left-1/2 w-1/2 top-3 sm:top-5">
                <div class="flex w-full justify-end">
                  <vs-dropdown class="flex cursor-pointer mr-5 items-center justify-center" :vs-trigger-click="true">
                    <vs-icon size="medium" color="#22292f" icon="more_vert"></vs-icon>
                    <vs-dropdown-menu class="w-40" style="z-index: 100001">
                      <vs-dropdown-item @click="initReportUser">
                        <div class="flex justify-center items-center">
                          <vs-icon class="mr-2">report_gmailerrorred</vs-icon>
                          <p>Report user</p>
                        </div>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
              <div>
                <div class="h-32 w-full object-cover lg:h-48 bg-gradient-to-b from-rag-green"></div>
              </div>
              <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                  <div class="flex">
                    <img
                      v-show="profileImageLoaded"
                      class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 object-cover"
                      :src="user.profileImage | formatPicture"
                      alt="profile_image"
                      @load="profileImgLoaded()"
                    />
                    <circle-skeleton
                      v-show="!profileImageLoaded"
                      class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                    ></circle-skeleton>
                  </div>
                  <div class="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                    <div class="sm:hidden 2xl:block min-w-0 flex-1">
                      <h2 class="text-2xl font-bold text-gray-900 truncate">
                        {{ returnName }}
                      </h2>
                    </div>
                    <div class="mt-2 sm:mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                      <portal-target name="chatButton"></portal-target>
                    </div>
                  </div>
                </div>
                <div class="hidden sm:block 2xl:hidden min-w-0 flex-1">
                  <h2 class="text-2xl font-bold text-gray-900 truncate">
                    {{ returnName }}
                  </h2>
                </div>
              </div>
            </div>

            <!-- Tabs -->
            <div id="tabs" class="mt-6 sm:mt-2 2xl:mt-5 overflow-auto overflow-y-hidden">
              <div class="border-b border-gray-200">
                <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                  <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <div
                      v-for="section in profileSections"
                      :key="section.name"
                      class="pr-4"
                      :class="showNavButton(section) ? '' : 'hidden'"
                    >
                      <div
                        v-if="showNavButton(section)"
                        :class="[
                          section.step === currentStep
                            ? 'border-rag-green  text-gray-900'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          ' cursor-pointer whitespace-nowrap py-4 px-1 border-b-4 border-t-0 border-solid  border-r-0 border-l-0 font-medium text-sm sm:text-base',
                        ]"
                        :aria-current="section.step === currentStep ? 'page' : undefined"
                        @click="navigateTo(section.step)"
                        >{{ section.name }}</div
                      >
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div class="mt-6 max-w-5xl mx-auto pb-10 px-4 sm:px-6 lg:px-8">
              <ProfileUserContent v-if="currentStep === 0" :input="user"></ProfileUserContent>
              <ProfileGuideInfo v-if="currentStep === 1" :input="user"></ProfileGuideInfo>
              <ProfileVehicles v-if="currentStep === 3" :input="user"></ProfileVehicles>
              <ProfileJobHistory v-if="currentStep === 4" :input="user.jobHistory"></ProfileJobHistory>
              <ProfileQualifications v-if="currentStep === 5" :user-id="user.userId" :input="user.documents"></ProfileQualifications>
              <ProfileReferences v-if="currentStep === 6" :input="user.references"></ProfileReferences>
            </div>
            <vs-popup class="overflow-visible" title="Why are you reporting this user?" :active.sync="reportUserPopup">
              <div>
                <p>All reports will be reviewed by our team and acted on should we find them to be valid. </p>
                <br />
                <p class="mb-5 sm:mb-5"><strong>Please provide details on why you want to report this user. </strong></p>
                <div class="overflow-hidden">
                  <vs-select
                    v-model="userReportReason"
                    :disabled="disableInputs"
                    class="w-full sm:mb-0 mb-5"
                    placeholder="Please select a reason"
                  >
                    <vs-select-item
                      v-for="(item, index) in filters.report_user_options['report_user_options']"
                      :key="index"
                      :value="item"
                      :text="item"
                    />
                  </vs-select>
                </div>
                <div class="mt-5">
                  <vs-textarea
                    v-if="userReportReason !== ''"
                    v-model="otherExplanation"
                    :disabled="disableInputs"
                    class="w-full"
                    placeholder="Please provide more information"
                  ></vs-textarea>
                </div>
              </div>
              <div class="vx-row mr-5 ml-0 mt-8">
                <vs-button
                  v-if="!reportSubmitting"
                  :disabled="disableReport"
                  color="danger"
                  icon-before
                  class="vx-col lg:w-1/3 relative"
                  @click="reportUser"
                  >Report User</vs-button
                >
                <vs-button v-else disabled color="danger" icon-before class="vx-col lg:w-1/3 relative">Submitting report...</vs-button>
                <vs-button
                  color="grey"
                  :disabled="reportSubmitting ? true : false"
                  class="vx-col lg:w-1/4 ml-3 relative"
                  @click="toggleReportModal"
                  >Cancel</vs-button
                >
              </div>
            </vs-popup>
          </article>
        </main>
        <ApplicationProfileTour />
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable vue/no-mutating-props -->
<!-- TODO FIX THIS -->
<script>
import ProfileUserContent from './ProfileUserContent';
import ProfileQualifications from './ProfileQualifications.vue';
import ProfileReferences from './ProfileReferences.vue';
import ProfileGuideInfo from './ProfileGuideInfo.vue';
import ProfileJobHistory from './ProfileJobHistory.vue';
import ProfileVehicles from './ProfileVehicles.vue';
import ApplicationProfileTour from '../tours/ApplicationProfileTour.vue';

import { v4 as uuidv4 } from 'uuid/dist';
import firebase from 'firebase/compat/app';

export default {
  name: 'ProfileUserProfile',

  components: {
    ProfileUserContent,
    ProfileQualifications,
    ProfileReferences,
    ProfileGuideInfo,
    ProfileJobHistory,
    ProfileVehicles,
    ApplicationProfileTour,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    showProfile: {
      type: Boolean,
    },
    showSkillsLangs: {
      type: Boolean,
    },
    showQual: {
      type: Boolean,
    },
    showRef: {
      type: Boolean,
    },
    showGuideInfo: {
      type: Boolean,
    },
  },
  data() {
    return {
      reportSubmitting: false,
      disableInputs: false,
      reportUserPopup: false,
      userReportReason: '',
      otherExplanation: '',
      showProfileMenu: false,
      currentStep: 0,
      profileSections: [
        { name: 'Profile', icon: 'person', step: 0 },
        { name: 'Guide Information', icon: 'hiking', step: 1, route: { name: 'Guide Information', path: 'guide-info' } },
        // {
        //   name: 'Languages, Skills & Interests',
        //   icon: 'interests',
        //   step: 2,
        //   route: { name: 'Language, Skills & Interests', path: 'language-and-skills' },
        // },
        { name: 'Vehicles', icon: 'directions_car', step: 3, route: { name: 'Vehicles', path: 'vehicles' } },
        { name: 'Job History', icon: 'work', step: 4, route: { name: 'Job History', path: 'job-history' } },
        {
          name: 'Documents',
          icon: 'school',
          step: 5,
          route: { name: 'Qualifications & Certificates', path: 'qualifications' },
        },
        { name: 'References', icon: 'fact_check', step: 6, route: { name: 'References', path: 'references' } },
      ],
      userProfile: {},
      input: {},
      profileImageLoaded: false,
    };
  },
  computed: {
    disableReport() {
      let disable;
      if (this.userReportReason !== 'Other') {
        this.userReportReason === '' ? (disable = true) : (disable = false);
      }
      if (this.userReportReason === 'Other' && this.otherExplanation === '') {
        disable = true;
      }
      return disable;
    },
    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
    returnName() {
      let name = '';
      if (this.user.firstName && this.user.lastName && this.user.firstName !== '' && this.user.lastName !== '') {
        name = `${this.user.firstName} ${this.user.lastName} `;
      } else if (this.user.fullName && this.user.fullName !== '') {
        name = this.user.fullName;
      } else name = 'No name to display';
      return name;
    },
  },
  methods: {
    closeProfile() {
      this.$store.dispatch('toggleSidebar', false);
    },
    profileImgLoaded() {
      this.profileImageLoaded = true;
    },
    initReportUser() {
      this.userReportReason = '';
      this.otherExplanation = '';
      this.reportUserPopup = true;
    },
    async reportUser() {
      this.disableInputs = true;
      this.reportSubmitting = true;
      const employer = this.$store.state.employerModule.data;
      try {
        const reportData = {
          userReported: true,
          reportedUser: this.user.userId,
          reportedUserName: this.user.firstName && this.user.lastName ? `${this.user.firstName} ${this.user.lastName}` : this.user.fullName,
          reportReason: this.userReportReason,
          otherExplanation: this.otherExplanation,
          reportedAt: new Date(),
          reportedBy: employer.employerId,
          reportedByName: `${employer.name} ${employer.surname}`,
          reportId: uuidv4(),
        };
        await firebase
          .firestore()
          .collection('users')
          .doc(this.user.userId)
          .update({ profileReported: firebase.firestore.FieldValue.arrayUnion(reportData) });
        await this.$store.dispatch('userReportsModule/set', reportData);
        this.finishReport();
      } catch (e) {
        console.log(e);
        this.finishReport();
      }
    },
    finishReport() {
      this.reportSubmitting = false;
      this.toggleReportModal();
      this.$store.dispatch('toggleGeneralPopup', {
        active: true,
        title: 'Your report has been submitted',
        text: 'Thank you for taking the time to report this guide profile. We will review the submission and take action where necessary.',
        buttonText: 'Okay',
        click: () => {
          this.$store.dispatch('toggleGeneralPopup', { active: false });
        },
      });
      this.disableInputs = false;
    },
    toggleReportModal() {
      this.reportUserPopup = !this.reportUserPopup;
    },
    showNavButton(button) {
      if (button.name === 'References') {
        if (!this.user.references) {
          return false;
        }
        if (this.user.references.length > 0 && this.user.references.every((ref) => ref.reported)) {
          return false;
        }
        if (this.user.references && this.user.references.length === 0) {
          return false;
        }
      }

      if (button.name === 'Job History') {
        if (!this.user.jobHistory) {
          return false;
        }
        if (this.user.jobHistory && this.user.jobHistory.length === 0) {
          return false;
        }
      }

      if (button.name === 'Documents') {
        if (!this.user.documents) {
          return false;
        }
        if (this.user.documents && this.user.documents.length === 0) {
          return false;
        }
      }

      if (button.name === 'Vehicles') {
        if (!this.user.vehicles) {
          return false;
        }
        if (this.user.vehicles && this.user.vehicles.length === 0) {
          return false;
        }
      }

      return true;
    },

    navigateTo(step) {
      this.currentStep = step;
      this.showProfileMenu = false;
    },
    checkArray(arr) {
      if (arr.length === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: inherit;
}
.profile-content {
  min-height: 100vh !important;
}
@import '@/assets/scss/vuesax/pages/profile.scss';

.con-vs-avatar {
  border-radius: 999px;
}
.vs-avatar--con-img {
  border-radius: 999px;
}
.background-vid {
  padding-top: 250px !important;
}

.nav-button-container {
  transition: background-color 0.5s ease;
  color: rgb(173, 202, 99);
  cursor: pointer;
  padding: 10px;
}

@media only screen and (max-width: 360px) {
  .nav-button-container {
    transition: background-color 0.5s ease;
    color: rgb(173, 202, 99);
    cursor: pointer;
    padding: 5px;
  }
}

.multiselect__tag {
  background: #adca63 !important;
}

.multiselect__option--highlight {
  background: #adca63 !important;
}

.nav-button-container:hover {
  background-color: rgba(173, 202, 99, 0.1);
}

.nav-button-active {
  background-color: rgb(173, 202, 99) !important;
  color: white;
}
.nav-button-inactive {
  background: transparent;
}

.nav-icon {
  color: unset;
  transition: color 0.5s ease;
}

#tabs::-webkit-scrollbar {
  display: none;
}
#tabs {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
