const userModule = {
  firestorePath: 'users',
  firestoreRefType: 'collection',
  moduleName: 'userModule',
  statePropName: 'data',
  namespaced: true,
  sync: {
    preventInitialDocInsertion: true,
  },

  state: {},
  getters: {
    getUser: (state) => {
      return state.data;
    },
    getUsers: (state) => {
      return state.data;
    },
  },
  mutations: {},
  actions: {
    getUser({ dispatch }, application) {
      return dispatch('fetchById', application.userId);
    },
    getRefUser({ dispatch }, userId) {
      return dispatch('fetchById', userId);
    },
    getUsers({ dispatch }) {
      dispatch('fetchAndAdd');
    },
    updateUser({ dispatch }, payload) {
      if (payload) {
        dispatch('patch', { ...payload });
        /*         .catch(console.error);
         */
      }
    },
  },
};

export default userModule;
