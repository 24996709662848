// NOTE
// Please use your own firebase details below. For more details visit: https://pixinvent.com/demo/vuesax-vuejs-admin-dashboard-template/documentation/development/firebaseIntegration.html

// import { initializeApp } from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/messaging';
import store from '../store/store';
// import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
// import { getStorage, ref } from 'firebase/storage';
// import { getMessaging, isSupported, getToken } from 'firebase/messaging';
import { isSupported, getToken } from 'firebase/messaging';

// Initialize Firebase
var config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const Firebase = firebase.initializeApp(config);
const db = getFirestore(Firebase);

getAnalytics(Firebase);

// firebaseApps previously initialized using initializeApp()

// connectFirestoreEmulator(db, 'localhost', 5002);

const auth = getAuth(Firebase);
onAuthStateChanged(auth, (user) => {
  if (user) {
    store.dispatch('auth/setFirebaseAdmin', user);
  } else {
    return;
  }
});

const analytics = getAnalytics(Firebase);
// const storage = getStorage(Firebase);
// const storageRef = ref(storage);

// let messaging;

// if (isSupported()) {
//   console.log('device is supported');
//   messaging = getMessaging(Firebase);

//   getToken(messaging, { vapidKey: 'BMUdKttHI5qEfbedu4WDJqo9_DNz1u39m19n5bDRs5-WEAXHjGlVsMcolKhJ1iQPrVFfVelv-UWS6P8Y9KSIRmM' })
//     // Send the Instance ID token your application server, so that it can:
//     // - send messages back to this app
//     // - subscribe/unsubscribe the token from topics

//     // [START refresh_token]
//     // Callback fired if Instance ID token is updated.
//     .then((refreshedToken) => {
//       /*         console.log("Token refreshed.");
//        */ // Indicate that the new Instance ID token has not yet been sent to the
//       // app server.
//       setTokenSentToServer(false);
//       // Send Instance ID token to app server.
//       sendTokenToServer(refreshedToken);
//       // [START_EXCLUDE]
//       // Display new Instance ID token and clear UI of all previous messages.
//       resetUI();
//       // [END_EXCLUDE]
//     });
//   /*       .catch((err) => {
//         console.log("Unable to retrieve refreshed token ", err);
//       }); */
//   // [END refresh_token]
// } else {
//   messaging = null;
// }

function isTokenSentToServer() {
  return window.localStorage.getItem('sentToServer') === '1';
}
function setTokenSentToServer(sent) {
  window.localStorage.setItem('sentToServer', sent ? '1' : '0');
}
function sendTokenToServer() {
  if (!isTokenSentToServer()) {
    /*     console.log("Sending token to server...");
     */ // TODO(developer): Send the current token to your server.
    // store.dispatch('employerModule/handleToken', currentToken);
    // console.log(currentToken);
    setTokenSentToServer(true);
  } else {
    /*   console.log(
      "Token already sent to server so won't send it again " +
        "unless it changes"
    ); */
  }
}

function resetUI() {
  // [START get_token]
  // Get Instance ID token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  getToken()
    .then((currentToken) => {
      if (currentToken) {
        /*         console.log(currentToken);
         */ sendTokenToServer(currentToken);
      } else {
        // Show permission request.
        /*         console.log(
          "No Instance ID token available. Request permission to generate one."
        ); */
        /*         console.log(
          "No Instance ID token available. Request permission to generate one."
        );
 */
        setTokenSentToServer(false);
      }
    })
    .catch(() => {
      /*       console.log("An error occurred while retrieving token. ", err);
       */ setTokenSentToServer(false);
    });
  // [END get_token]
}
function initFirebase() {
  if (isSupported()) {
    resetUI();
  }
  // return new Promise((resolve, reject) => {
  //   Firebase.firestore()
  //     .enablePersistence()
  //     .then(resolve)
  //     .catch((err) => {
  //       if (err.code === 'failed-precondition') {
  //         // reject(err);
  //         console.log(err);
  //         // Multiple tabs open, persistence can only be
  //         // enabled in one tab at a a time.
  //       } else if (err.code === 'unimplemented') {
  //         reject(err);
  //         // The current browser does not support all of
  //         // the features required to enable persistence
  //       }
  //     });
  // });
}

const { TimeStamp, GeoPoint } = Firebase;
export { TimeStamp, GeoPoint, Firebase, initFirebase, analytics, auth, db };
