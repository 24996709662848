const companiesModule = {
  firestorePath: 'companies',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'companiesModule',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    preventInitialDocInsertion: false,
    insertHookBeforeSync: function (updateFirestore, doc, store) {
      store.commit('companiesModule/ADD_COMP', doc);
      updateFirestore(doc);
    },
    default: {
      companyId: '',
      employerId: '',
      name: '',
      type: '',
      contact_no: '',
      website: '',
      email: '',
      logo: '',
      banner: '',
      accommodation: '',
      meals: '',
      flights: '',
      tips: '',
      leave: '',
      bio: '',
    },
  },
  serverChange: {
    addedHook: function (updateStore, doc, id, store) {
      store.commit('companiesModule/ADD_COMP', doc);
      updateStore(doc);
    },
  },

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {
    compArr: [],
  },
  getters: {
    getJobTotal: (state) => {
      return Object.keys(state.data).length;
    },
    getJobList: (state) => {
      return state.compArr;
    },
    getComp: (state) => {
      return Object.values(state.data)[0];
    },
  },
  mutations: {
    UPDATE_COMPANY_LIST(state, { id, title }) {
      const comp = {};
      comp[id] = title;
      state.compArr.push(comp);
    },
    ADD_COMP(state, doc) {
      state.compArr.push(doc);
    },
  },
  actions: {},
};

export default companiesModule;
