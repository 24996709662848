<template>
  <div class="md:grid grid-cols-2">
    <div class="col-span-1">
      <div class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Job Position</p>
          <p class="item-name font-semibold my-1"> {{ input.guideType }} Guide</p>
        </div>
      </div>
      <div v-if="input.guideCategory && input.guideCategory !== ''" class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Category</p>
          <p class="item-name font-semibold my-1"> {{ input.guideCategory }} </p>
        </div>
      </div>
      <div class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Tour Guide Number</p>
          <p class="item-name font-semibold my-1"> {{ input.tourGuideNumber || 'N / A' }}</p>
        </div>
      </div>
      <div class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Rate</p>
          <p v-if="input.minSalary && input.minSalary !== 0" class="item-name font-semibold my-1">
            <span v-if="input.currency">{{ input.currency }}</span> {{ input.minSalary }}
          </p>
          <p v-else class="item-name font-semibold my-1"> No rate selected </p>
        </div>
      </div>
      <div class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Payment Term</p>
          <p class="item-name font-semibold my-1"> {{ input.salaryTerm || 'No term selected' }} </p>
        </div>
      </div>
    </div>
    <div class="col-span-1">
      <div class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Country of Operation</p>
          <p class="item-name font-semibold my-1"> {{ input.location || 'No country selected' }} </p>
        </div>
      </div>
      <div v-if="input.provinces.length > 0" class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Provinces of Operation</p>
          <ul>
            <li v-for="(provinces, index) in input.provinces" :key="index"
              ><p class="item-name font-semibold my-1"> {{ provinces }}</p></li
            >
          </ul>
        </div>
      </div>
      <div v-if="input.siteRegional" class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Site / Region of Operation</p>
          <ul v-if="input.siteRegional">
            <li v-for="(site, index) in sites" :key="index"
              ><p class="item-name font-semibold my-1"> {{ site }}</p></li
            >
          </ul>
          <p v-else class="item-name font-semibold my-1"> No sites selected</p>
        </div>
      </div>
      <div class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Work Permits</p>

          <ul v-if="input.passports && input.passports.length > 0">
            <li v-for="(permits, index) in input.passports" :key="index"
              ><p class="item-name font-semibold my-1"> {{ permits }}</p></li
            >
          </ul>
          <p v-else class="item-name font-semibold my-1"> No permits selected</p>
        </div>
      </div>
      <div class="vx-row">
        <div class="px-4 py-2">
          <p class="text-xs text-dark-grey">Skills and Interests</p>
          <ul>
            <li v-for="(skills, index) in input.specArea" :key="index"
              ><p class="item-name font-semibold my-1"> {{ skills }}</p></li
            >
          </ul>
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <p v-if="input.languagesNew" class="text-xs text-dark-grey my-3">Languages</p>
      <guide-audio-player
        v-for="(language, index) in input.languagesNew"
        :key="index"
        :language="language"
        :index="index"
      ></guide-audio-player>
    </div>
  </div>
</template>

<script>
import GuideAudioPlayer from './GuideAudioPlayer.vue';
export default {
  name: 'ApplicationGuideInfo',
  components: {
    GuideAudioPlayer,
  },
  props: {
    input: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sites() {
      let text = this.input.siteRegional;
      const sitesArray = text.split(',');
      return sitesArray;
    },
  },
};
</script>
