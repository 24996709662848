/*=========================================================================================
  File Name: moduleChat.js
  Description: Chat Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from './moduleChatState.js';
import mutations from './moduleChatMutations.js';
import actions from './moduleChatActions.js';
import getters from './moduleChatGetters.js';

const chatModule = {
  firestorePath: 'messages',
  firestoreRefType: 'collection',
  moduleName: 'chatModule',
  sync: {
    insertHook: function (updateStore, doc) {
      // eslint-disable-next-line no-param-reassign
      doc.id = doc.applicationsId;
      updateStore(doc);
    },
    patchHook: function (updateStore, doc) {
      updateStore(doc);
    },
    deleteHook: function (updateStore, id) {
      updateStore(id);
    },
  },
  serverChange: {
    addedHook: function (updateStore, doc, id, store) {
      const data = store.state['chatModule'].data;
      const user = store.getters['applicationsModule/DataOfUser'](id);
      // eslint-disable-next-line no-prototype-builtins
      if (!data.hasOwnProperty(id)) {
        var pair;
        if (doc.lastSentBy === doc.userId) {
          pair = { pinned: true, userData: user.userData };
          const newDoc = { ...doc, ...pair };

          updateStore(newDoc);
        } else {
          pair = { pinned: false, userData: user.userData };
          const newDoc = { ...doc, ...pair };
          updateStore(newDoc);
        }
      }
    },
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      createdAt: '%convertTimestamp%', // default
      updatedAt: '%convertTimestamp%',
    },
  },

  statePropName: 'data',
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default chatModule;

// export default {
//   namespaced: true,
//   state: state,
//   mutations: mutations,
//   actions: actions,
//   getters: getters,
//   chatModule: chatModule
// };
