<template>
  <div>
    <ul>
      <li v-for="(vehicle, index) in input.vehicles" :key="index">
        <vx-card class="mb-5 border-solid border-2 border-grey-light">
          <!-- <div class="sm:grid grid-cols-2 gap-5 border-solid border-2 border-grey-light rounded-lg mb-4 sm:mb-3"> -->
          <div class="sm:grid grid-cols-2 gap-5">
            <img class="vehicle_img rounded-lg cols-span-1" :src="image(vehicle)" />

            <div class="cols-span-1 sm:pl-3 mt-4 sm:mt-0 flex flex-col justify-center">
              <div class="font-semibold"> {{ vehicle.make }} </div>
              <div v-if="vehicle.vehicleType" class="text-xs text-dark-grey"> {{ vehicle.vehicleType }} </div>
              <div class="text-xs text-dark-grey"> {{ vehicle.seats }} Seater </div>
              <div v-if="vehicle.insuranceCover" class="text-xs text-dark-grey sm:mt-3 mt-2"> Insurance Provider </div>
              <div v-if="vehicle.insuranceCover" class="font-semibold"> {{ vehicle.insuranceCover }} </div>
              <div v-if="vehicle.generalLiability" class="text-xs text-dark-grey sm:mt-3 mt-1"> General Public Liability </div>
              <div v-if="vehicle.generalLiability" class="font-semibold">
                <span v-if="vehicle.generalLiabilityCurrency">{{ vehicle.generalLiabilityCurrency }}</span>
                {{ numberWithCommas(vehicle.generalLiability) }}
              </div>
              <div v-if="vehicle.generalLiability" class="text-xs text-dark-grey sm:mt-3 mt-1"> Passenger Liability Cover </div>
              <div v-if="vehicle.passengerLiability" class="font-semibold">
                <span v-if="vehicle.passengerLiabilityCurrency">{{ vehicle.passengerLiabilityCurrency }}</span>
                {{ numberWithCommas(vehicle.passengerLiability) }}
              </div>
            </div>
          </div>
        </vx-card>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    input: {
      type: Object,
      required: true,
    },
  },
  methods: {
    image(vehicle) {
      if (vehicle.doc && vehicle.doc !== null) {
        return vehicle.doc;
      } else return 'https://i.imgur.com/gFUUFpR.png';
    },
    numberWithCommas(cover) {
      return cover.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};
</script>

<style scoped>
.vehicle_img {
  width: 100%;
  height: 10rem;
  object-fit: cover;
}

@media (min-width: 576px) {
  .vehicle_img {
    height: 15rem;
  }
}
</style>
